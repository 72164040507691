import React, { useState } from 'react';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { Container, Typography, TextField, Button, Box, Paper, Grid, CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import epicOdyssey from './assets/epicOdyssey.webp';
import CircularProgress from '@mui/material/CircularProgress';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#673ab7',
    },
    background: {
      default: '#121212',
      paper: '#1e1e1e',
    },
  },
  typography: {
    fontFamily: '"Merriweather", serif',
    h2: {
      fontWeight: 700,
    },
    h5: {
      fontStyle: 'italic',
    },
  },
});

function App() {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');


  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    const formData = new FormData(event.currentTarget);
    
    //fixme validate email
    const email = formData.get('email');

    const data = {
        to: email,
        dynamicTemplateData: {
            name: "Guest"
        }
    }

    if (email) {
      // Use the Fetch API to send a POST request
      fetch("https://api.odyss.ing/send-mail", {
        method: 'POST', // Specify the method
        headers: {
            'Content-Type': 'application/json', // Specify the content type as JSON
        },
        body: JSON.stringify(data), // Convert the JavaScript object to a JSON string
      })
      .then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        // add error handling here if needed


        return response;
      })
      .then(data => {
        console.log('Success:', data); // Handle the success case
        setMessage('Thanks for subscribing!');
        setOpen(true);
        setEmail('');
        setLoading(false);
        event.currentTarget.reset(); // Reset the form fields
      })
      .catch((error) => {
        console.error('Error:', error); // Handle errors
      })
      .finally(() => {
        setLoading(false);
      });

    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  
    setOpen(false);
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="static" color="primary">
        <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant="h7" color="secondary">
            This is a temporary page. A new version is coming soon.
          </Typography>
        </Toolbar>
      </AppBar>
      
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>

        <Container maxWidth="md" sx={{ mt: 8 }}>
          <Paper elevation={3} sx={{ p: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: '100%',
                  }}
                >
                  <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold' }}>
                    Embark on an Epic Odyssey: Transform, Triumph, Transcend
                  </Typography>
                  <Typography variant="h6" component="h2" gutterBottom>
                    "After the storm has passed, you might not remember the struggle to get through it, or even be sure it's over. But one thing is clear: the person who entered the storm isn't the same one who comes out. That's the true magic of enduring the storm."
                  </Typography>
                  <Typography variant="h5" component="h3" gutterBottom sx={{ mt: 2, mb: 2 }}>
                    Embrace Your Odyssey. Transform Through Tempests.
                  </Typography>
                  <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      value={email}
                      onChange={handleChange}
                    />
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                      {loading ? <CircularProgress size={24} /> : 'Join the Journey'}
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    height: 300,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'grey.300',
                    overflow: 'hidden'
                  }}
                >
                  <img style={{height:"390px"}} src={epicOdyssey} alt='Embrace your Odyssey' />
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default App;
